import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import BaseLayout from '../components/layouts/BaseLayout';
import Stage from '../components/layouts/Stage';
import StageIntro from '../components/elements/StageIntro';
import Map from '../components/elements/Map';

import { Section } from '../components/layouts/Containers';
import { Headline, P } from '../components/elements/Typography';
import { Img } from '../components/elements/Images';
import { shapeImage } from '../helpers/shapes';
import { mq } from '../helpers/stylehelpers';

const PrizeImage = styled(Img)`
    max-width: 80%;
    margin: 0 auto 1.25em;

    ${mq.medium`
        max-width: 60%;
        margin-bottom: 2em;
    `};
`;

const PrizeSmall = styled(Img)`
    max-width: 260px;
    margin: 0 auto 1.25em;
    width: 50%;

    ${mq.medium`
        max-width: 25%;
        margin-bottom: 2em;
    `};
`;

const PrizesPage = ({ data }) => (
    <BaseLayout
        title="Preise"
        description="Auflistung der Preise beim LOTTO Sportjugend-Förderpreis"
    >
        <Stage>
            <StageIntro
                headline={{ text: 'Die Preise', smaller: true }}
                copy="Das gibt es beim LOTTO Sportjugend-Förderpreis zu gewinnen:"
            />
        </Stage>

        <Section container="m">
            <Headline level="h2" gap="l">
                Preisgelder und Sonderpreise
            </Headline>
            <P textAlign="center" gap="l">
                In zehn Regionen des Landes werden jeweils mindestens sechs Preisträger ermittelt.
                Über die Vergabe der Preise entscheidet eine unabhängige Jury. Die Hauptpreise sind
                mit 2.000 Euro (Regionalsieger), 1.500 Euro (Platz 2), 1.200 Euro (Platz 3), 1.000
                Euro (Platz 4), 800 Euro (Platz 5) und 500 Euro (Platz 6) dotiert.
            </P>

            <P textAlign="center" gap={{ small: 'l', medium: 'xl', large: 'xxl' }}>
                Weitere <strong>10 Preise</strong> zu je 500 Euro werden unabhängig von der
                regionalen Zugehörigkeit vergeben.
            </P>

            <PrizeImage src={data.prizeImage.childImageSharp.fluid} alt="Preise" />

            <P textAlign="center" gap={{ small: 'l', medium: 'xl', large: 'xxl' }}>
                Die genaue Platzierung bleibt bis zur Preisverleihung geheim. Unter den Teilnehmern
                werden <strong>drei Landessieger</strong> ermittelt, die ein{' '}
                <strong>Preisgeld von insgesamt 15.000 Euro</strong> erhalten. Die Gewinner werden
                bei der Siegerehrung bekannt gegeben.
            </P>

            <PrizeSmall src={data.prizeLandessieger.childImageSharp.fluid} alt="Preise" />

            <P textAlign="center" gap={{ small: 'l', medium: 'xl', large: 'xxl' }}>
                Weiterhin werden bis zu <strong>10 Sonderpreise</strong> für die besten Aktionen mit
                besonders hohem Engagement während der Corona-Pandemie vergeben. Die Sonderpreise
                sind mit jeweils 1.000 Euro dotiert.
            </P>

            <PrizeSmall src={data.prizeExtra.childImageSharp.fluid} alt="Preise" />

            <P textAlign="center" gap={{ small: 'l', medium: 'xl', large: 'xxl' }}>
                Die Preisträger werden im Rahmen einer festlichen{' '}
                <strong>Siegerehrung im Europa-Park Dome</strong> in Rust geehrt. Die eingeladenen
                Vereinsvertreter erhalten zudem freien Eintritt in den Freizeitpark.
            </P>

            <P gap="xl">
                Die Jury behält sich vor, die Anzahl der Preise entsprechend zu reduzieren, falls in
                einer Region weniger als zehn Bewerbungen eingehen, die den Kriterien entsprechen.
                Die Entscheidung der Jury ist endgültig und nicht anfechtbar. Der Rechtsweg ist
                ausgeschlossen.
            </P>
            <strong>
                Die Siegerehrung findet voraussichtlich im Juli 2021 statt. Die Gewinner werden im
                April 2021 bekannt gegeben.
            </strong>
        </Section>

        <Section container="m">
            <Headline level="h2">
                100.000 Euro für die pfiffigsten Aktionen in der Vereinsjugend
            </Headline>
            <P textAlign="center">
                Der LOTTO Sportjugend-Förderpreis ist mit{' '}
                <strong>insgesamt 100.000 Euro dotiert</strong>. Prämiert werden Aktionen in der
                Vereinsjugendarbeit aus den Jahren 2019 und 2020.
            </P>

            <Map />
        </Section>
    </BaseLayout>
);

PrizesPage.propTypes = {
    data: PropTypes.shape({
        prizeImage: shapeImage,
        PrizeSmall: shapeImage,
    }).isRequired,
};

export default PrizesPage;

export const query = graphql`
    query PrizesQuery {
        prizeImage: file(relativePath: { eq: "content/prize.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        prizeLandessieger: file(relativePath: { eq: "content/prize_landessieger.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        prizeExtra: file(relativePath: { eq: "content/sonderpreise.png" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
    }
`;
