import styled from 'styled-components';
import React from 'react';

import { remCalc } from '../../helpers/stylehelpers';

import mapRegions from '../../images/content/map-regions.svg';
import mapDistricts from '../../images/content/map-districts.svg';

/**
 * Container
 * @type {StyledComponent}
 */
const Wrapper = styled.div`
    display: none;

    @media (min-width: 500px) {
        cursor: pointer;
        display: block;
        position: relative;
        max-width: ${remCalc(500)};
        margin: 1em auto 1.5em;
        &:hover {
            > img:nth-child(1) {
                opacity: 0;
            }
            > img:nth-child(2) {
                opacity: 1;
            }
        }
    }
`;

/**
 * Image
 * @type {StyledComponent}
 */
const Image = styled.img`
    transition: 0.25s opacity;

    &:nth-child(2) {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
    }
`;

/**
 * Kleine Hinweis unter der Karte
 * @type {StyledComponent}
 */
const Hint = styled.small`
    display: block;
    font-size: ${remCalc(15)};
    margin-top: 0.5em;
    text-align: center;
`;

/**
 * Regionskarte, die bei Mouse-Over die Land bzw. Stadtkreise zeigt
 */
const Map = () => (
    <Wrapper>
        <Image src={mapRegions} />
        <Image src={mapDistricts} />
        <Hint>Bewegen Sie Ihre Maus über die Karte, um die Stadt- bzw. Landkreise anzuzeigen.</Hint>
    </Wrapper>
);

export default Map;
